import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import "../layout/base.css";

import LogoImage from "../assets/logo-white.svg";
import PaulBackground from "../assets/paul-background.jpg";

const PageContainer = styled.div`
  background: linear-gradient(to bottom right, #000, #111);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100%;
`;

const BackgroundImage = styled.div`
  background-image: url(${PaulBackground});
  background-size: cover;
  background-position: center;
  filter: grayscale(50%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const Logo = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 4rem;
  z-index: 1;

  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 961px) {
    width: 400px;
    height: 120px;
  }
`;

const Content = styled.div`
  background: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.2)
  );
  backdrop-filter: blur(50px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 2rem;
  text-align: center;
  position: relative;
  z-index: 2;

  @media (min-width: 961px) {
    max-width: 50vw;
    padding: 4rem 2rem;
  }

  h1 {
    color: #fff;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 900;
    padding: 0;
    margin: 0;
    text-transform: uppercase;

    @media (min-width: 961px) {
      font-size: 3.5rem;
      line-height: 3rem;
    }
  }

  p {
    font-weight: 300;
    padding: 0;
    max-width: 80%;
  }

  a {
    background: linear-gradient(to bottom right, #226fe2, #4791ff);
    border-radius: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.2s ease;
    transform: translateY(0);
    padding: 1rem 2rem;
    margin-top: 2rem;

    &:hover {
      opacity: 0.9;
      transform: translateY(-5px);
    }
  }
`;

class RootIndex extends React.Component {
  render() {
    return (
      <PageContainer>
        <BackgroundImage />
        <Helmet title="TimeCrunchFitness - Currently under construction" />
        <Logo>
          <img src={LogoImage} alt="Logo" />
        </Logo>
        <Content>
          <h1>Currently under construction</h1>
          <p>
            We are making some exciting new developments here at Time Crunch
            Fitness Solutions and look forward to welcoming you back really
            soon.
          </p>
          <p>
            In the meantime, if you are ready to gain more confidence and
            clarity around your health and fitness, schedule a free call with me
            now to learn more about how I can help you
          </p>
          <a href="https://timecrunchfitnesssolutionsbookings.as.me/bookyourcallwithPaul">
            Book a call
          </a>
        </Content>
      </PageContainer>
    );
  }
}

export default RootIndex;
